<template>
  <div style="position: relative; height: 600px;">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import { Chart } from 'chart.js';

export default {
  props: ['chartData', 'hideLegend'],
  data() {
    return {
      chartInstance: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              fontColor: '#333',
            },
            gridLines: {
              display: false,
            },
          }],
          yAxes: [
            {
              id: 'y1',
              stacked: false,
              ticks: {
                fontColor: '#333',
                callback: function (value) {
                  return '$' + value + 'M';
                },
              },
              gridLines: {
                color: '#ddd',
              },
            },
            {
              id: 'y2',
              stacked: false,
              position: 'right',
              ticks: {
                fontColor: '#333',
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 20,
            padding: 15,
          },
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += Math.round(tooltipItem.yLabel * 100) / 100;
              return label;
            }
          }
        },
        layout: {
          padding: {
            bottom: 40,
          },
        },
      },
    };
  },
  methods: {
    renderChart() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      this.chartInstance = new Chart(this.$refs.canvas, {
        type: 'bar',
        data: this.chartData,
        options: this.options,
      });
    },
    update() {
      if (this.chartInstance) {
        this.chartInstance.update();
      }
    },
  },
  mounted() {
    if (this.hideLegend) {
      this.options.legend.display = false;
    }
    this.renderChart();
  },
  watch: {
    chartData: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },
};
</script>