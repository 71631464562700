<template>
  <form method="post" autocomplete="off" @submit.prevent="handleTopSearchDeals">
    <div class="columns">
      <div class="column">
        <div class="field mb-5">
          <label class="label">Region</label>
          <div class="control">
            <div class="select">
              <select name="region" v-model="region">
                <option value=""> - Please Select -</option>
                <optgroup label="Canada">
                  <option value="AB">Alberta</option>
                  <option value="BC">British Columbia</option>
                  <option value="MB">Manitoba</option>
                  <option value="NB">New Brunswick</option>
                  <option value="NL">Newfoundland and Labrador</option>
                  <option value="NS">Nova Scotia</option>
                  <option value="NT">Northwest Territories</option>
                  <option value="NU">Nunavut</option>
                  <option value="ON">Ontario</option>
                  <option value="PE">Prince Edward Island</option>
                  <option value="QC">Quebec</option>
                  <option value="SK">Saskatchewan</option>
                  <option value="YT">Yukon</option>
                </optgroup>

                <optgroup label="USA">
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Round Type</label>
          <div class="control">
            <div class="select">
              <select name="roundType" v-model="roundType">
                <option value=""> - Please Select - </option>
                <option value="1693">Pre-Seed</option>
                <option value="1695">Seed</option>
                <option value="1696">Series A</option>
                <option value="1697">Series B</option>
                <option value="1698">Series C</option>
                <option value="1699">Series D</option>
                <option value="1700">Series E</option>
                <option value="1701">Series F</option>
                <option value="1702">Series G</option>
                <option value="1703">Series H</option>
                <option value="1704">Series I</option>
                <option value="1705">Series J</option>
                <option value="1706">Series K</option>
                <option value="1707">Series L</option>
                <option value="1708">Series M</option>
                <option value="1709">Series N</option>
                <option value="1692">Debt</option>
                <option value="1694">Private Equity</option>
                <option value="1710">Unknown</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field mb-5">
          <label class="label">Deal Type</label>
          <div class="control">
            <div class="select">
              <select name="dealType" v-model="dealType">
                <option value=""> - Please Select - </option>
                <option value="VC">Any VC</option>
                <option value="PE">Any PE</option>
                <optgroup label="Venture Capital">
                  <option value="1674">Seed</option>
                  <option value="1665">Early Stage</option>
                  <option value="1667">Later Stage</option>
                  <option value="1666">Growth</option>
                  <option value="1671">Pre-IPO</option>
                  <option value="1670">PIPE</option>
                  <option value="1675">Venture Debt</option>
                  <option value="1664">Bridge</option>
                  <option value="1672">Secondary</option>
                  <option value="1663">M&A</option>
                  <option value="1662">RTO</option>
                  <option value="1668">Other</option>
                </optgroup>

                <optgroup label="Private Equity">
                  <option value="1682">Growth</option>
                  <option value="1681">Follow-On</option>
                  <option value="1679">Buyout</option>
                  <option value="1688">Privatization</option>
                  <option value="1689">Recapitalization</option>
                  <option value="1680">Debt</option>
                  <option value="1676">Add-On Acquisition</option>
                  <option value="1678">M&A</option>
                  <option value="1677">RTO</option>
                  <option value="1686">Platform Creation</option>
                  <option value="1687">Private Placement</option>
                  <option value="1683">Infrastructure</option>
                  <option value="1684">Other</option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Sector</label>
          <div class="control">
            <div class="select">
              <select class="input" name="sector" v-model="sector">
                <option value=""> - Please Select -</option>
                <option v-for="(obj,id) in sectorList" :key="id" :value="obj.name">
                  {{ obj.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column ">
        <div class="field mb-5">
          <div class="controlvdp-datepicker date-input">
            <label class="label">Date After</label>
            <datepicker
                v-model="dateAfter"
                format="yyyy-MM-dd"
                class="date-input"
            ></datepicker>
            <input type="hidden" tabindex="-1" name="dateAfter" :value="dateAfter" class="hidden">
          </div>
        </div>
        <div class="field">
          <div class="control vdp-datepicker date-input">
            <label class="label">Date Before</label>
            <datepicker
                v-model="dateBefore"
                format="yyyy-MM-dd"
                class="date-input"
            ></datepicker>
            <input type="hidden" tabindex="-1" name="dateBefore" :value="dateBefore" class="hidden">
          </div>
        </div>
      </div>
    </div>
    <div class="field-body">
      <div class="control">
        <button class="button is-link">Search</button>
        &nbsp;
        <a href="#" class="button is-link is-light" @click="resetForm">Reset</a>
      </div>
    </div>

  </form>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

export default {
  components: {
    Datepicker
  },

  data() {
    return {
      searchQueryDeals: '',
      siteUrl: '',
      assetUrl: '',
      region: '',
      dealType: '',
      roundType: '',
      sector: '',
      dateBefore: '',
      dateAfter: '',
      isLoading: false,
      deals: [],
      sectorList: {
        1608: {
          name: "Agribusiness",
          options: ["Advanced Agriculture", "Agribiotechnology", "Agricultural Products & Related Activities", "Animal Production", "Aquaculture", "Crop Production", "Greenhouses", "Other Agribusiness"]
        },
        1609: {
          name: "Automotive & Transportation",
          options: ["Air Freight & Logistics","Airlines","Airport & Services","Automobile Manufacturing","Automobile Parts","Highways & Rail tracks","Marine Ports & Services","Marine Transportation","Other Transportation Logistics","Road, Rail Transportation","Transportation Services"]
        },
        1610: {
          name: "Business Products & Services",
          options: ["Advertising, Marketing & PR","Building & Properties","Commercial Printing","Consulting & Outsourcing","Education & Training","Facilities Management","HR & Staffing","Information & Records Management","Legal Services","Office furniture & Equipment","Office Supplies","Other Business Products & Services","Packaging","Wholesale"]
        },
        1611: {
          name: "CleanTech",
          options: ["Advanced Materials","Alternative Energy Equipment","Biofuels","Energy Efficiency","Energy Storage","Fuel Cell & Other Electric Technology","Hydro ROR Power","Integrated Renewal Energy","Other CleanTech Related","Solar Power","Thermal and Geothermal","Wind Power"]
        },
        1612: {
          name: "Computer Hardware & Services",
          options: ["Computer Networking Equipment","Computer Product Distribution & Support Services","Computer Storage & Peripherals","Gaming Systems","Handheld Computers & Accessories","IT Services & Outsourcing","Other Computer Hardware & Services","Personal Computers & Notebooks","Servers & Mainframes","Stealth Mode Computer Hardware & Services","Supercomputers","Wearable Technologies","Workstations & Thin Clients"]
        },
        1613: {
          name: "Consumer Products & Services",
          options: ["Apparel & Accessories","Consumer Durables","Consumer Non Durables","Franchise and Related","Other Products & Services"]
        },
        1614: {
          name: "eCommerce",
          options: ["Apparel & Accessories","Auto","Computer & Software","Digital Goods","Discount, Group Buying","Electronics & Appliances","Events & Ticketing","Food & Grocery","Home Furnishings & Improvement","Jewelry","Marketplace","Multi-Products","Music, Video, Books & Entertainment","Office Products","Other Retail & Services","Pharmacies","Tools & Platforms","Toys & Games","Travel (Internet)"]
        },
        1615: {
          name: "Electronic & Semiconductor",
          options: ["Chips & Semiconductors","Electrical Product Distribution","Electrical Products","Electronic Components","Electronic Manufacturing Services (EMS)","Electronic Test, Measurement & Monitoring","Other Electronics","Technical & Scientific Instrumentation"]
        },
        1616: {
          name: "Energy & Power",
          options: ["Energy Storage","Energy Trading & Marketing","Gas/Coal Electric Generation","Oil & Gas Equipment","Oil & Gas Exploration & Production","Oil & Gas Refining & Distribution","Oil & Gas Services","Oil & Gas Storage & Transport","Other Energy Power Related"]
        },
        1617: {
          name: "Environmental",
          options: ["Agri BioProducts","Environmental & Energy Consulting","Hazardous Waste Services","Industrial BioProducts","Other Environmental","Recycling","Remediation & Environmental Cleanup","Renewable Ingredients","Solid Waste","Waste Water & Treatment"]
        },
        1618: {
          name: "Financial",
          options: ["Accounting","Asset & Financial Management","Banks","Credit & Collections","Insurance & Re-insurance","Investment Banking","Investment Firms & Funds","Lending Institutions","Other Financial","Payments (non internet/mobile)","Real Estate","Securities Brokers & Traders","Stock Exchanges"]
        },
        1619: {
          name: "Food & Beverages",
          options: ["Alcoholic Beverages","Bottling & Distribution","Candy & Snackfoods","Canned & Frozen Foods","Dairy Products","Food Safety & Preservation","Fresh Foods","Functional Foods","Ingredients, Flavoring & Condiments","Meat, Fish & Seafood","Natural and Organic Consumer Packaged Goods","Non Alcoholic Beverages","Other Food & Beverages","Wholesale Food Distributors"]
        },
        1620: {
          name: "Forestry",
          options: ["Forest Services","Lumber Manufacturing","Lumber Products","Other Forestry","Paper Products","Wood Products"]
        },
        2977: {
          name: "Healthcare/Life Sciences",
          options: ["Biopharma Services","Dental Products & Services","Diagnostics","Drug Acquisition & Licensing","Drug Delivery","Drug Discovery","Drug Manufacturing & Distribution","eHealth and IT","Health & Wellness","Healthcare Plans","Healthcare Services","Medical Device & Equipment","Medical Facilities & Services","Other Life Sciences Medical Related","Stealth Mode","Therapeutic Drugs & Biologics","Therapeutic Medical Devices"]
        },
        2978: {
          name: "Industrial & Manufacturing",
          options: ["Aerospace & Defense","Chemicals","Construction & Engineering","Construction Materials","Containers & Packaging","Machinery & Equipment","Manufacturing","Other Industrial & Materials","Textiles"]
        },
        2979: {
          name: "Internet Software & Services",
          options: ["Accounting & Finance","Ad Network & Exchange","Advertising, Sales & Marketing","Application, Systems & Utilities","Asset & Financial Management & Trading","BI, Analytics & Performance Management","Billing, Expense Management & Procurement","Collaboration & Project Management","Compliance","Conferencing & Communication","Construction & Design","Content Management","Content Providers, News, Discussion","Customer Relationship Management","Data & Document Management","Data Storage","Database Management","Domain & SEO Services","Education & Training","Gambling","Games and Apps","Government","Green & Environmental","HR & Workforce Management","ISP & Web Hosting","Legal","Loan & Finance","Manufacturing, Warehousing & Industrial","Monitoring & Security","Multimedia & Graphics","Music","Networking & Connectivity","Other Internet Software & Services","Payments","Photo, Video","Real Estate","Retail Software","Scientific, Engineering","Search","Social, Online Community","Sports","Stealth Mode","Supply Chain & Logistics","Travel","Web Development"]
        },
        2980: {
          name: "Leisure",
          options: ["Casinos & Gaming","Entertainment","Hotels & Resorts","Other Leisure","Restaurants","Sports & Recreation"]
        },
        2981: {
          name: "Media (Traditional)",
          options: ["Broadcasting, Radio & Television","Film & Video","Integrated Media","Music","Other Media","Publishing"]
        },
        2982: {
          name: "Metals & Mining",
          options: ["Aluminum","Brokers & Distribution","Coal","Copper & Other Non Ferrous Metals","Diamond & Precious Stones","Diversified Metals & Mining","Gold & Silver","Other Metals & Mining","Precious Metals (non Gold and Silver)","Steel"]
        },
        2983: {
          name: "Mobile & Telecommunications",
          options: ["Fiber Optics","Internet Appliances/Devices","Mobile Commerce","Mobile Devices & Services","Mobile Payment","Mobile Software & Services","Other Mobile & Telecommunications","RFID Systems","Stealth Mode Mobile & Telecommunications","Telecom Devices & Equipment","Telecom/Internet Infrastructure","Telecom/Mobile Services"]
        },
        2984: {
          name: "Real Estate",
          options: ["Commerical & Industrial Properties","Development/Builder","Office Properties","Other Real Estate Related","Property Management","Residential Properties","Retirement Properties"]
        },
        2985: {
          name: "Retail (Brick & Mortar)",
          options: ["Apparel & Accessories","Auto","Catalog, Mail Order & TV Sales","Computer & Software","Convenience Stores","Department Stores","Electronics & Appliances","Gasoline","Grocery","Home Furnishings & Improvement","Jewelry","Music, Video, Books & Entertainment","Office Products","Other Retail","Pharmacies","Services","Sporting Goods","Toys & Games"]
        },
        2986: {
          name: "Security",
          options: ["Business and Consumer Security Products & Services","Other Security Related"]
        },
        2987: {
          name: "Software (Non Internet/Mobile)",
          options: ["Accounting & Finance","Advertising, Sales & Marketing","Application & Data Integration","Asset Management & Trading","BI, Analytics & Performance","Billing, Expense, Procurement","CleanTech, Environmental","Collaboration & Project Management","Compliance","Conferencing & Communication","Construction & Design","Content Management","Customer Relationship Management","Data & Document Management","Database Management","Education & Training","Email, Multimedia & Graphics","Financial Services","Gaming","Government","HR & Workforce Management","Legal","Manufacturing, Supply Chain, Logistics","Networking & Connectivity","Other Software","Product Lifecycle Management","Real Estate","Retail","Scientific, Engineering","Security","Stealth Mode","Storage & Systems Management","Systems & Utility"]
        },
        2988: {
          name: "Utilities",
          options: ["Electric Utilities","Gas Utilities","Multi Utilities","Other Utilities","Water Utilities"]
        },
        2991: {
          name: "Other",
          other: true,
        },
      }
    }
  },

  computed: {
    dateBeforeShort() {
      return this.dateBefore ? this.dateBefore.toISOString().slice(0, 10) : ''
    },

    dateAfterShort() {
      return this.dateAfter  ? this.dateAfter.toISOString().slice(0, 10)  : ''
    }
  },

  mounted () {
    this.siteUrl = window.siteUrl
    this.assetUrl = window.assetUrl
  },

  methods: {
    handleTopSearchDeals() {
      const queryParams = new URLSearchParams();

      if (this.region) queryParams.set('region', this.region);
      if (this.dealType) queryParams.set('dealType', this.dealType);
      if (this.roundType) queryParams.set('roundType', this.roundType);
      if (this.sector) queryParams.set('sector', this.sector);
      if (this.dateBefore) queryParams.set('dateBefore', this.dateBefore);
      if (this.dateAfter) queryParams.set('dateAfter', this.dateAfter);

      const searchUrl = window.IntelligenceURL + `/search?${queryParams.toString()}`;
      window.location.href = searchUrl;
    },
    resetForm() {
      this.region = '';
      this.dealType = '';
      this.roundType = '';
      this.sector = '';
      this.dateBefore = '';
      this.dateAfter = '';
    }
  },
}
</script>