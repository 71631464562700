<template>
  <form method="post" autocomplete="off" @submit.prevent="handleTopSearchInvestors">
    <div class="columns">
      <div class="column">
        <div class="field mb-5">
          <div class="field-label has-text-left">
            <label class="label">Type</label>
          </div>
          <div class="field-body select is-multiple">
            <div class="field multiselect-wrapper">
              <multiselect
                  v-model="investorType"
                  :options="investortypes"
                  :multiple="true"
                  placeholder="Begin typing an investor type"
                  :preselect-first="false"
                  :preserve-search="true"
                  open-direction="bottom"
              ></multiselect>
            </div>
          </div>
        </div>

        <div class="field mb-5">
          <div class="field-label has-text-left">
            <label class="label">Headquarters</label>
          </div>
          <div class="field-body select is-multiple">
            <div class="field multiselect-wrapper">
              <multiselect
                  v-model="investorLocation"
                  :options="investorLocationSuggestions"
                  :multiple="true"
                  :options-limit="( investorLocationQuery.length >= 1 ) ? 7 : 0"
                  placeholder="Begin typing a location (city, province, country)"
                  :preselect-first="false"
                  :preserve-search="true"
                  open-direction="bottom"
                  @search-change="onInvestorLocationSearchChange"
              >
                <span slot="noOptions" class="noOptions"></span>
              </multiselect>
            </div>
          </div>
        </div>

        <div class="field">
          <div class="field-label has-text-left">
            <label class="label">Number of Funds</label>
          </div>
          <div class="field-body select is-multiple">
            <div class="field multiselect-wrapper">
              <multiselect
                  v-model="investorFunds"
                  :options="['1', '2-4', '5-9', '10+']"
                  :multiple="true"
                  placeholder="Select the number of funds"
                  :preselect-first="false"
                  :preserve-search="true"
                  open-direction="bottom"
              ></multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field mb-5">
          <div class="field-label has-text-left">
            <label class="label">Sector / Vertical</label>
          </div>
          <div class="field-body select is-multiple">
            <div class="field multiselect-wrapper">
              <multiselect
                  v-model="dealSector"
                  :options="sectors"
                  :multiple="true"
                  placeholder="Begin typing a sector, sub-sector, or vertical"
                  :preselect-first="false"
                  :preserve-search="true"
                  open-direction="bottom"
              ></multiselect>
            </div>
          </div>
        </div>

        <div class="field mb-5">
          <div class="field-label has-text-left">
            <label class="label">Deal Details</label>
          </div>
          <div class="field-body select is-multiple">
            <div class="field multiselect-wrapper">
              <multiselect
                  v-model="dealType"
                  :options="dealtypes"
                  :multiple="true"
                  placeholder="Begin typing a round, deal structure, capital type, etc"
                  :preselect-first="false"
                  :preserve-search="true"
                  open-direction="bottom"
              ></multiselect>
            </div>
          </div>
        </div>

        <div class="field">
          <div class="field-label has-text-left">
            <label class="label">Location</label>
          </div>
          <div class="field-body select is-multiple">
            <div class="field multiselect-wrapper">
              <multiselect
                  v-model="dealLocation"
                  :options="dealLocationSuggestions"
                  :multiple="true"
                  :options-limit="( dealLocationQuery.length >= 1 ) ? 7 : 0"
                  placeholder="Begin typing a location (city, province, country)"
                  :preselect-first="false"
                  :preserve-search="true"
                  open-direction="bottom"
                  @search-change="onDealLocationSearchChange"
              >
              </multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="field-body">
      <div class="control">
        <button class="button is-link">Search</button>
        &nbsp;
        <a href="#" class="button is-link is-light" @click="resetForm">Reset</a>
      </div>
    </div>
  </form>

</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },

  data() {
    return {
      siteUrl: '',
      assetUrl: '',
      investorLocation: [],
      investorType: [],
      investorFunds: [],
      dealLocation: [],
      dealSector: [],
      dealType: [],
      lastSearch: '',
      dealLocationQuery: '',
      investorLocationQuery: '',
      isLoading: false,
      noResults: false,
      investors: []
    }
  },

  props: {
    locations: {
      type: Array,
      default: () => []
    },

    dealtypes: {
      type: Array,
      default: () => []
    },

    sectors: {
      type: Array,
      default: () => []
    },

    investortypes: {
      type: Array,
      default: () => []
    }
  },

  mounted () {
    this.siteUrl  = window.siteUrl
    this.assetUrl = window.assetUrl
    this.IntelligenceURL = window.IntelligenceURL
  },

  computed: {
    dealLocationSuggestions() {
      return ( this.dealLocationQuery.length <= 3 )
          ? this.locations.filter(word => word.toUpperCase().startsWith( this.dealLocationQuery.toUpperCase() ) )
          : this.locations ?? []
    },

    investorLocationSuggestions() {
      return ( this.investorLocationQuery.length <= 3 )
          ? this.locations.filter(word => word.toUpperCase().startsWith( this.investorLocationQuery.toUpperCase() ) )
          : this.locations ?? []
    }
  },

  methods: {
    onDealLocationSearchChange(query) {
      this.dealLocationQuery = query
    },

    onInvestorLocationSearchChange(query) {
      this.investorLocationQuery = query
    },
    handleTopSearchInvestors() {
      const queryParams = new URLSearchParams();

      if (this.investorLocation.length > 0) queryParams.set('investorLocation', JSON.stringify(this.investorLocation));
      if (this.investorType.length > 0) queryParams.set('investorType', JSON.stringify(this.investorType));
      if (this.investorFunds.length > 0) queryParams.set('investorFunds', JSON.stringify(this.investorFunds));
      if (this.dealSector.length > 0) queryParams.set('dealSector', JSON.stringify(this.dealSector));
      if (this.dealLocation.length > 0) queryParams.set('dealLocation', JSON.stringify(this.dealLocation));
      if (this.dealType.length > 0) queryParams.set('dealType', JSON.stringify(this.dealType));

      const searchUrl = window.IntelligenceURL + `/search/investors?${queryParams.toString()}`;
      window.location.href = searchUrl;
    },
    resetForm() {
      this.investorLocation = '';
      this.investorType = '';
      this.investorFunds = '';
      this.dealSector = '';
      this.dealLocation = '';
      this.dealType = '';
    }

  },
}
</script>