<template>
  <div style="position: relative; height: 500px;">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import {Chart} from 'chart.js';

export default {
  props: ['chartData', 'hideLegend'],
  data() {
    return {
      chartInstance: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              fontColor: '#333',
            },
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              fontColor: '#333',
              callback: function (value) {
                return value;
              },
            },
            gridLines: {
              display: false,
            },
          }],
        },
        legend: {
          display: !this.hideLegend,
          position: 'bottom',
          labels: {
            boxWidth: 20,
            padding: 15,
          },
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        layout: {
          padding: {
            bottom: 40,
          },
        },
      },
    };
  },
  methods: {
    renderChart() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      this.chartInstance = new Chart(this.$refs.canvas, {
        type: 'bar',
        data: this.chartData,
        options: this.options,
      });
    },
    update() {
      if (this.chartInstance) {
        this.chartInstance.update();
      }
    },
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    chartData: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },
};
</script>