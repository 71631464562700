<template>
  <form method="post" autocomplete="off">
    <div class="field multiselect-wrapper mb-0 ml-2" style="width: 270px;">
      <multiselect
          v-model="involved"
          :options="options"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="false"
          placeholder="Search CVCA Intelligence"
          label="name"
          track-by="name"
          :preselect-first="false"
          open-direction="bottom"
          @select="viewCompany"
          @search-change="asyncFind"
          @open="multiselectOpen=true"
          @close="multiselectOpen=false"
      >

        <span slot="noResult" v-if="isLoading">Searching ...</span>
        <span slot="noResult" class="noResult" v-if="!isLoading" >
                    <a
                        href="#"
                        @click.prevent="createCompany = true"
                    ><span>No companies found that matches <em>{{lastSearch}}</em>.</span>
                    </a>
                </span>
        <span slot="noOptions" class="noOptions">
                    Find VCs, PEs, LPs, and Companies
                </span>
      </multiselect>
    </div>

    <div v-if="multiselectOpen" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgb(0 0 0 / 50%); z-index: 100;"></div>

    <div class="modal" :class="{ 'is-active': isSwitching }">
      <div class="modal-background"></div>
      <div class="modal-content has-background-white p-4 pt-6 has-text-centered" style="border-radius: 2rem;">
        <div class="title is-2">Loading Company</div>
        <p>Give us a second, this will just take a moment.</p>
        <img src="/assets/intelligence/icons/loading.gif" width="100" class="is-inline">
      </div>
    </div>
  </form>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },

  data() {
    return {
      siteUrl: '',
      assetUrl: '',
      multiselectOpen: false,
      createCompany: false,
      isLoading: false,
      lastSearch: '',
      involved: [],
      options: [],
      isSwitching: false,
    }
  },

  mounted () {
    this.siteUrl = window.siteUrl
    this.assetUrl = window.assetUrl
  },

  methods: {
    viewCompany: function(company) {
      if( company && Object.prototype.hasOwnProperty.call(company, "id") && company.id >= 1 ) {
        this.isSwitching = true
        window.location = siteUrl + '/company/' + company.id
      }
    },

    async asyncFind(query) {
      this.lastSearch = query
      if( query.length >= 3 ) {
        this.isLoading = true
        this.options = await fetch(
            window.IntelligenceURL + `/json/companies?q=${encodeURI(query)}`, {
              method: 'GET',
              credentials: 'same-origin',
              redirect: 'follow',
              agent: null,
            }
        ).then( res => { return res.json() }
        ).then( companies => { return companies.items })
        this.isLoading = false
      }
    },
  },
}
</script>